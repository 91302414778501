import React from 'react';
import Grid from '@material-ui/core/Grid';
import './App.css';
import ResumeCard from './ResumeCard.js';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

function App() {
  const experiences = [
    {
      role: "Software Engineer",
      company: "What's in Your Wallet?",
      division: "",
      city: "",
      state: "",
      startDate: "Jan 2023",
      endDate: "Present",
      description: (
        <span>
          A new adventure.
        </span>
      ),
      projects: [],
      logoUrl: "c1-logo.jpeg",
    },
    {
      role: "Software Engineer",
      company: "NYC Department of City Planning",
      division: "Planning Labs",
      city: "New York",
      state: "New York",
      startDate: "May 2019",
      endDate: "Jan 2023",
      description: (
        <span>
          I am part of Open Source Engineering, (formerly <a href="https://labs.planning.nyc.gov">Labs</a>) a division practicing
          modern web development to deliver a large suite of applications to
          City Planning.
        </span>
      ),
      projects: [
        {
          title: "Equitable Development Data Explorer",
          link: "https://equitableexplorer.planning.nyc.gov/",
          description: "An innovative tool to explore spatial and tabular, Planning-curated displacement risk data for New York City.",
          press: [
            {
              link: "https://www1.nyc.gov/site/planning/data-maps/edde/edde-overview.page#:~:text=The%20Equitable%20Development%20Data%20Explorer%20is%20a%20new%20interactive%20resource,racial%20equity%20in%20our%20city.&text=Bibliography%20contains%20resources%20reviewed%20by,development%20of%20the%20data%20tool.",
              title: "About the Equitable Development Data Explorer"
            }
          ]
        },
        {
          title: "Applicant Portal",
          link: "https://applicants.planning.nyc.gov/",
          description: (
            <span>
              The modern, digital service for submitting land use application to City Planning.
            </span>
          ),
          press: [
            {
              link: "https://www1.nyc.gov/site/planning/about/press-releases/pr-20200929.page",
              title: (<span>NYC Planning Press Release - <i>A First: Some Land Use Documents Can Now Be Filed Digitally</i></span>)
            },
            {
              link: "https://www.citylandnyc.org/dcp-launches-updated-digital-land-use-portal-online-application-tracker/",
              title: (<span>CityLand - <i>DCP Launches Updated Digital Land Use Portal, Online Application Tracker</i></span>)
            },
            {
              link: "https://therealdeal.com/2020/10/01/zoning-process-enters-21st-century-with-online-filing/",
              title: (<span>The Real Deal - <i>Zoning process enters 21st century with online filing</i></span>)
            },
          ],
          photoUrl: "",
        },
        {
          title: "Population Factfinder",
          link: "https://popfactfinder.planning.nyc.gov/",
          description: "Recently re-architected to deliver 2020 census data. Users can explore social, demographic and economic data at census-tract level or neighborhood level through maps and dynamic tables.",
          press: [
            {
              link: "https://www1.nyc.gov/site/planning/about/press-releases/pr-20211104.page",
              title: "Press Release: Population FactFinder Updated with 2020 Census Data",
            }
          ]
        },
        {
          title: "Land Use Participation Portal",
          link: "https://zap.planning.nyc.gov/",
          description: (
              <span>
                A tool for the public to search land use applications, and for New York's borough boards, borough presidents, and community boards to submit hearings
                and recommendations for land use applications as a part of the Uniform Land Use Review Procedure (ULURP).
             </span>),
             press: [
              {
                link: "https://www1.nyc.gov/site/planning/about/press-releases/pr-20210114.page",
                title: (<span>NYC Planning Press Release- <i>ZAP: DCP Launches New and Improved Digital Land Use Portal</i></span>)
              },
            ],
          photoUrl: "",
        },
        {
          title: "City Environmental Quality Review",
          link: "https://www.ceqr.app/",
          description: (
            <span>
              A tool for planners and consultants to review environmental impacts of proposed developments across the city.
            </span>
          ),
          photoUrl: "",
        }
      ],
      logoUrl: "dcp-logo.png",
    },
    {
      role: "Geospatial Developer",
      company: "Oregon Metro",
      division: "Research Center",
      city: "Portland",
      state: "Oregon",
      startDate: "Feb 2017",
      endDate: "May 2019",
      achievements: [
        "",
      ],
      description: (
        <span>
          Part of the Research Center, I delivered a number of progressive, spatially enabled web applications
          to different departments like Parks &amp; Nature, Planning, and Regional Illegal Dumping. 
        </span>
      ),
      projects: [
        {
          title: "Economic Value Atlas",
          link: "http://evatool.oregonmetro.gov/",
          description: (
            <div>
              A tool for exploring economic, demographic and infrastructure indicator data through dynamic maps and D3 graphs.
              Developed in collaboration with the Brookings Institute and Metro's Planning division.
            </div>
          ),
          press: [
            {
              link: "https://www.brookings.edu/research/announcing-the-economic-value-atlas-a-new-approach-to-regional-transportation-and-land-use-planning/",
              title: (<span>Brookings - <i>Announcing the Economic Value Atlas: A new approach to regional transportation and land use planning</i></span>)
            },
            {
              link: "https://datasmart.ash.harvard.edu/news/article/map-monday-regional-tool-economic-development",
              title: (<span>Harvard Kennedy School Ash Center - <i>Map Monday: A Regional Tool for Economic Development</i></span>)
            },
            {
              link: "https://www.planetizen.com/news/2019/06/104696-economic-value-atlas-new-tool-assessing-economic-development",
              title: (<span>Planetizen - <i>The 'Economic Value Atlas': A New Tool for Assessing Economic Development</i></span>)
            },
            {
              link: "https://www.oregonmetro.gov/tools-partners/guides-and-tools/economic-value-atlas",
              title: (<span>Metro's guide to the Economic Value Atlas</span>)
            },
          ],
          photoUrl: ""
        },
        {
          title: "Regional Illegal Dumping",
          link: "https://ridpatrol.oregonmetro.gov/report",
          description: (
            <span>
              A mobile-friendly, flexbox application where Metro citizens can report illegally dumped garbage. A mapping and geocoding component helps users
              report the illegal dumping location, or determine if it was already reported by someone else.
            </span>
          ),
          photoUrl: "",
        },
        {
          title: "Trail Counts",
          description: (
            <span>
              I led development for a pair of mobile-friendly tools, one for volunteers and one for administrators, for counting usage types (pedestrian, cyclists, scooter, etc) at trails around the region.
            </span>
          ),
          photoUrl: "",
        },
      ],
      logoUrl: "metro-logo.png",
    },
    {
      role: "IT GIS Intern",
      company: "City of Salem",
      division: "IT",
      city: "Salem",
      state: "Oregon",
      startDate: "July 2016",
      endDate: "Feb 2017",
      achievements: [],
      description: (
        <span>
          Part of the wonderful IT GIS team, I made heavy use of ArcGIS JS and ArcGIS WebAppBuilder to build spatial reference and analysis tools for the city website and planners.
          Topics included environmental review, parking, road work, urban renewal areas, skateboarding, floodplains, utilities, and more.
        </span>
      ),
      projects: [],
      logoUrl: "salem-logo.png",
    },
    {
      role: "Software Engineering Intern",
      company: "Placemeter",
      division: "",
      city: "New York",
      state: "New York",
      startDate: "June 2015",
      endDate: "September 2015",
      achievements: [], 
      description: "",
      projects: [
        {
          title: 'Real-time pedestrian visualization',
          description: (
            <div>
              A real-time visualization of foot traffic from sensors around Union Square, Manhattan, and Dilworth Park, Philadelphia.
            </div>
          ),
          press: [
            {
              link: "https://www.bloomberg.com/news/articles/2016-02-09/this-real-time-map-tracks-pedestrians-and-bikes-in-new-york-s-union-square",
              title: (<span>Bloomberg CityLab - <i>Watch New Yorkers Hurry Across Union Square in Real-Time</i></span>)
            },
            {
              link: "https://dirt.asla.org/2015/09/16/placemeter-measures-the-flow-of-people-through-urban-spaces/",
              title: (<span>ASLA's The Dirt - <i>Placemeter Measures the Flow of People Through Urban Spaces</i></span>)
            },
          ],
        }
      ],
      logoUrl: "placemeter-logo.png",
    },
  ]

  return (
      <Grid
        container
        className="root"
        justifyContent="space-between"
        style={{
          backgroundColor: "rgb(253,253,253)",
        }}
      >
        <Grid
          container
          item
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          xs={12}
          md={4}
        >
            <Grid
              item
              style={
                {
                  padding: "50px 0 25px 0"
                }
              }
            >
              <img src="godfrey-gh-trim.jpeg" 
                style={{
                  maxHeight: "300px",
                  clipPath: "circle(50%)"
                }}
                alt="Profile pic"
              />
            </Grid>
            <Grid
              item
              style={{
                padding: "10px 0"
              }}
            >
              <Typography variant="h4">
                Godfrey Yeung
              </Typography>
            </Grid>
            <Grid
              item
            >
              <Typography variant="subtitle1">
                <Link href="https://github.com/godfreyyeung">
                  GitHub
                </Link>
                &nbsp;|&nbsp;
                <Link href="https://www.linkedin.com/in/godfreyyeung">
                  LinkedIn
                </Link>
                &nbsp;|&nbsp;
                <Link href="https://medium.com/@godfreyyeung">
                  Medium
                </Link>
              </Typography>
            </Grid>

            <Grid item>
                <Typography
                  variant="h5"
                  color="textSecondary"
                  align="center"
                  style={
                    {
                      padding: '15px',
                    }
                  }
                >
                  Hello! I'm software-for-good enthusiast.
                  For the past six years I've had the opportunity to work in local
                  government, building software used by both planners and citizens to 
                  improve quality of life in their region.
                </Typography>
            </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
          justifyContent="flex-start"
          direction="column"
          container
          style={
            {
              padding: '30px 20px',
            }
          }
        >
          <Typography variant="h5" style={
            {
              marginBottom: '1rem',
            }
          }>
            My Journey
          </Typography>
          <div>
            {experiences.map((experience, idx) => (<ResumeCard info={experience} key={idx} />))}
          </div>
        </Grid>
      </Grid>
  );
}

export default App;
